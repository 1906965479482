/* globals $ */
import { get } from "@rails/request.js"

document.addEventListener('triggerJQuery', function () {
  if (!($('.promotions').length > 0)) { return }

  $('#promo_clear_selects').click(function () {
    $("select[multiple='multiple'] option").prop('selected', false)
    $('#promotion_product_segments').change()
    return false
  })

  $('#promotion_product_segments, #promotion_price_types, #promotion_offer_types, #promotion_term_months, #promotion_term_end_dates, #promotion_utility_ids, #promotion_broker_ids, #promotion_channels').change(function () {
    // const params = $('form').serialize()
    let data = new FormData(document.querySelector('form.new_promotion, form.edit_promotion'))
    data.delete('promotion[product_ids][]');
    get(`/promotions/products?${new URLSearchParams(data).toString()}`, { responseKind: 'turbo-stream' })
  })
})
